.c-example-box {
  @apply rounded;
  @apply mb-4;
  @apply overflow-hidden;

  &__title {
    @apply px-2;
    @apply py-1;
    @apply bg-blue-200;
    @apply text-blue-800;
    @apply text-xs;
    @apply font-semibold;
    @apply uppercase;
  }

  &__content {
    @apply p-2;
    @apply bg-blue-100;
    @apply text-sm;

    pre {
      @apply text-xs;
    }

    p:first-child {
      @apply mt-0;
    }

    :last-child {
      @apply mb-0;
    }
  }
}
