.c-tabs {
  @apply flex;
  @apply flex-wrap;
  @apply self-start;

  > label,
  > a {
    @apply flex;
    @apply cursor-pointer;
    @apply z-10;
    @apply px-3 py-2;
    @apply font-medium text-sm;
    @apply leading-5;
    @apply print:hidden;

    > span {
      @apply inline-block;
      @apply ml-3;
      @apply px-2.5;
      @apply rounded-full;
      @apply text-xs font-medium leading-5;
    }
  }

  > input {
    @apply absolute;
    @apply opacity-0;
  }

  > div {
    @apply hidden;
    @apply w-full;
    @apply order-last;
    @apply mt-px;
  }

  > input:checked + label + div {
    @apply block;
  }

  &--cards {
    > label,
    > a {
      @apply rounded-t-lg;
      @apply border border-b-0;
      @apply bg-gray-100 text-gray-600;
      @apply -ml-px mr-2;
      @apply px-4 py-3;
    }

    > label span,
    > a span {
      @apply bg-gray-200;
      @apply text-gray-900;
    }

    > label:hover,
    > a:hover {
      @apply text-gray-800;
    }

    > label:active {
      @apply bg-white;
      @apply text-blue-700;
    }

    > input:checked + label,
    > a.active {
      @apply bg-white;
      @apply text-blue-700;

      span {
        @apply bg-blue-100;
        @apply text-blue-900;
      }
    }

    > input:focus + label {
      @apply ring;
      @apply z-10;
    }

    > div {
      @apply mt-0 rounded-t-none;
    }
  }

  &--pills {
    > label,
    > a {
      @apply rounded-md;
      @apply text-gray-600;
    }

    > label span,
    > a span {
      @apply bg-gray-200;
      @apply text-gray-900;
    }

    > label:hover,
    > a:hover {
      @apply text-gray-800;
    }

    > label:active {
      @apply bg-gray-200;
      @apply text-gray-800;
    }

    > input:checked + label,
    > a.active {
      @apply bg-gray-200;
      @apply text-gray-800;

      span {
        @apply bg-gray-300;
        @apply text-gray-600;
      }
    }

    > input:focus + label {
      @apply ring;
      @apply z-10;
    }

    > div {
      @apply mt-2;
    }
  }

  &--underline {
    > label,
    > a {
      @apply text-gray-500;
      @apply border-b-2 border-transparent;
      @apply py-2 px-3;
      @apply mr-2;
    }

    > label span,
    > a span {
      @apply bg-gray-100;
      @apply text-gray-900;
    }

    > label:hover,
    > a:hover {
      @apply text-gray-700;
      @apply border-gray-300;
    }

    > label:active,
    > a.active,
    > input:checked + label {
      @apply text-blue-900;
      @apply border-blue-600;

      span {
        @apply bg-blue-100;
        @apply text-blue-900;
      }
    }

  }
}
