:root {
  --max-width: 1200px;
  --section-spacing: 2rem;
}

.bg-gradient {
  @apply bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700;
}


.g-intro {
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 300;
  color: theme('colors.gray.800');
  width: 80%;
  margin: 0 auto 1.5em;
  text-align: center;

  @apply sm:w-full;
}

.g-copy {
  @apply text-xl;

  @apply sm:text-base;
}

.g-highlight {
  border-radius: 1em 0 1em 0;
  background-image: linear-gradient(-100deg, rgba(250,247,133,0.5), rgba(250,247,133,0.9) 95%, rgba(250,247,133,0.3));
}

.g-highlight2 {
  border-radius: 1em 0 1em 0;
  background-image: linear-gradient(-100deg, rgba(162,93,236,0.3), rgba(162,93,236,0.8) 95%, rgba(162,93,236, 0.2));
}

.container {
  @screen sm {
    @apply px-3;
  }
}

.container.narrow {
  @apply px-40;

  @screen sm {
    @apply px-3;
  }

  @screen print {
    @apply px-20;
  }
}

footer {
  @keyframes wiggle {
    0% {transform: rotate(10deg); }
    25% {transform: rotate(-10deg) scale(1.5); }
    50% {transform: rotate(20deg) scale(1.5); }
    75% {transform: rotate(-5deg) scale(1.5); }
    100% {transform: rotate(0deg); }
  }

  .icon-heart {
    &:hover {
      animation-name: wiggle;
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
    }
  }
}

.g-floating-quotes {
  grid-column: 3 / -3;
  column-count: 3;
  column-gap: 2rem;

  @screen sm {
    column-count: 1;
  }

  blockquote {
    @apply p-4;
    break-inside: avoid;
  }

  blockquote:not(:first-child) {
    @apply mt-8;
  }
}
