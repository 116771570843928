.c-page-title {
  padding: 2.5em 0 3em;
  text-align: center;
  margin: 0 auto;
  position: relative;
  width: 100%;

  @apply print:mt-0 print:mx-0 print:mb-8;

  h1 {
    @apply print:mt-4;
  }

  @apply sm:p-4;

  &__back {
    font-size: 0.9em;
    margin: 0 0.75rem;
    position: absolute;
    left: 0;
    line-height: 1;
    top: 2em;
    color: theme('colors.gray.800');
    font-size: 2.5em;

    @apply sm:top-10;
    @apply sm:text-3xl;

    &:hover,
    &:visited,
    &:active {
      color: theme('colors.gray.800');
    }
  }

  &--with-back {
    @apply sm:pl-8;
  }
}
