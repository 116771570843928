 .c-guide {
  margin-top: 5em;
  margin-bottom: 5em;
  padding: 0 0.75em;

  @apply print:mt-0 print:mb-0;
  @apply sm:mt-0 sm:mb-20;

   &__toc {
    padding-right: 1.5em;
    padding-top: 0.25em;
    position: sticky;
    top: 100px;
    float: left;
    width: 30%;
    min-height: 200px;

    @apply text-lg;
  }

   &__toc-link {
    color: theme('colors.gray.600');
    @apply font-medium;
    border-left: 4px solid theme('colors.gray.300');
    padding: 3px 15px;
    display: flex;
    margin-bottom: 5px;

     &:hover {
      color: theme('colors.blue.700');
    }

    &:active,
    &:focus,
    &:visited {
      color: theme('colors.gray.600');
    }

     &--current {
      color: theme('colors.blue.600') !important;
      border-color: theme('colors.blue.600');
    }
  }

  h2 {
    margin-top: -22px;
    padding-top: 34px;

    &:not(:first-of-type) {
      border-top: 1px solid theme('colors.gray.100');
      padding-top: 30px;
      margin-top: 30px;
    }
  }

  .prose {
    float: right;
    width: 70%;
    max-width: initial;

    h2:first-child {
      margin-top: -32px;
    }

    @apply sm:w-full;
    @apply print:w-full;
  }

  .c-mini-cta {
    @apply p-6;
    @apply border-0;

    &__content {
      @apply w-1/2;
      @apply flex;
      @apply flex-col;
      @apply items-start;

      @apply sm:w-full;
      @apply sm:text-center;
      @apply sm:items-center;

      &__text {
        @apply text-xl;
      }

      .c-button {
        @apply text-lg;
        padding: 12px 14px;
      }
    }

    &__screenshot {
      @apply w-1/2;
      @apply ml-8;
      @apply flex;
      @apply justify-end;

      @apply sm:w-full;
      @apply sm:justify-center;
      @apply sm:mb-3;
      @apply sm:ml-0;

      img {
        width: 250px;
      }
    }
  }
}
