.c-mini-cta {
  display: flex;
  @apply sm:flex-col;

  align-items: center;
  border: 1px solid theme('colors.gray.300');
  @apply rounded-md;
  background: theme('colors.gray.100');
  padding: 0.75em 1em;

  &__content {
    &__text {
      @apply text-xs;
      color: theme('colors.gray.600');
    }
  }

  &__screenshot {
    flex-shrink: 0;
    @apply sm:order-first;
    margin-left: 1em;
  }
}
