.c-timeline {
  &__timeline {
    @apply flex flex-col items-center;
    @apply mt-16;
  }

  &__year {
    @apply inline-flex items-center justify-center;
    @apply text-white font-bold sm:text-sm;
    @apply bg-blue-500;
    @apply w-16 h-16 sm:w-12 sm:h-12;
    @apply rounded-full;
  }

  &__item {
    @apply grid grid-cols-2 grid-flow-col-dense sm:text-sm;

    div:first-child {
      @apply flex flex-col;
    }

    div:last-child {
      @apply flex;
    }

    .c-timeline__content {
      @apply bg-gray-100;
      @apply border rounded-lg;
      @apply p-2 my-2;
      @apply font-semibold;
    }

    time {
      @apply text-sm text-gray-500 font-medium;
    }

    &:nth-of-type(odd) {
      > div:first-child {
        @apply pr-2;
        @apply border-r-2 border-blue-500;
      }
    }

    &:nth-of-type(even) {
      > div:first-child {
        @apply col-start-2;
        @apply pl-2;
      }

      > div:last-child {
        @apply col-start-1;
        @apply border-r-2 border-blue-500;
      }
    }
  }
}
