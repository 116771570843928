.c-vacation-calculator-faq {
  @apply mt-12;

  &__entry {
    &__question {
      color: theme('colors.blue.900');
      @apply text-lg;
      @apply font-semibold;

      display: flex;
      flex-display: row;
      align-items: center;

      cursor: pointer;

      svg {
        margin-right: .3em;
      }
    }

    .icon-plus { display: none; }
    .icon-minus { display: inline-block; }

    &--collapsed {
      .icon-plus { display: inline-block; }
      .icon-minus { display: none; }

      .c-vacation-calculator-faq__entry__answer {
        display: none;
      }
    }

    padding: 1em 0;
    border-bottom: 1px solid theme('colors.gray.300');
    &:last-of-type {
      border-bottom: none;
    }
  }
}
