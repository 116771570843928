#calc-table {
  tbody tr td,
  tfoot tr td {
    @apply border-t border-gray-200;
  }

  tbody tr {
    &:hover { @apply bg-blue-100; }
  }
}
