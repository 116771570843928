.c-hakuna-breadcrumb {
  @apply inline-flex;
  @apply items-center;
  @apply relative;
  @apply overflow-hidden;
  @apply bg-blue-50;
  @apply text-sm text-blue-900;

  padding: 5px 5px;
  @apply pl-9;

  &__logo {
    @apply inline-flex;
    @apply items-center;
    @apply justify-center;
    @apply absolute;
    @apply left-0;
    @apply top-0;
    @apply bottom-0;
    @apply bg-blue-700;
    @apply w-7;
  }
}
